
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { UserUtils } from "@/sea/utils/UserUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import { getAdministratorsRequest } from "@/sea/apis/AdministratorsService";
import Datatable from "@/sea/components/datatables/Datatable.vue";
import { useI18n } from "vue-i18n/index";
import { StringUtils } from "@/sea/utils/StringUtils";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "administrators",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();

    const search = ref<string | null>(null);
    const isEnabled = ref<boolean | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);
    const sort = ref<string | null>(null);

    search.value = route.query.search ? `${route.query.search}` : null;
    isEnabled.value = StringUtils.toBoolean(`${route.query.isEnabled}`) ?? null;
    pageNumber.value = Number(route.query.pageNumber) || 1;
    pageSize.value = Number(route.query.pageSize) || 10;

    const generateTableHeaders = () => {
      return [
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.administrators.tblHeaderName"
          ),
          key: "username",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.administrators.tblHeaderEmail"
          ),
          key: "email",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.administrators.tblHeaderEnabled"
          ),
          key: "isEnabled",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.administrators.tblHeaderDate"
          ),
          key: "date",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.administrators.tblHeaderActions"
          ),
          key: "actions",
        },
      ];
    };

    const tableHeaders = ref(generateTableHeaders());

    watch(locale, () => {
      tableHeaders.value = generateTableHeaders();
    });

    const {
      adminsRef,
      loadingRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      getAdministrators,
    } = getAdministratorsRequest();

    const onIsEnabledChange = async (event) => {
      isEnabled.value = StringUtils.toBoolean(event.target.value);
      await getData();
    };

    const updateQueries = async () => {
      const newQuery = Object.assign({}, route.query);
      if (search.value) {
        newQuery.search = search.value;
      } else {
        delete newQuery.search;
      }
      if (isEnabled.value === true || isEnabled.value === false) {
        newQuery.isEnabled = isEnabled.value.toString();
      } else {
        delete newQuery.isEnabled;
      }
      if (pageNumber.value) newQuery.pageNumber = pageNumber.value.toString();
      if (pageSize.value) newQuery.pageSize = pageSize.value.toString();
      await router.replace({ query: newQuery });
    };

    const getData = async () => {
      await updateQueries();
      await getAdministrators(
        search.value,
        isEnabled.value,
        sort.value,
        pageNumber.value,
        pageSize.value
      );
      reinitializeComponents();
    };

    const sortAdmins = async (order) => {
      if (order) {
        sort.value = `${order.columnName},${order.order}`;
        await getData();
      }
    };

    const updatePageSize = async (sizePerPage) => {
      pageSize.value = sizePerPage;
      await getData();
    };

    const updatePageNumber = async (numberOfPage) => {
      pageNumber.value = numberOfPage;
      await getData();
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.administrators.title", [
        "sea.aside.categories.management.title",
      ]);
      await getData();
    });

    return {
      I18nUtils,
      UserUtils,
      DateTimeUtils,
      search,
      isEnabled,
      onIsEnabledChange,
      tableHeaders,
      getData,
      loadingRef,
      adminsRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      sortAdmins,
      updatePageSize,
      updatePageNumber,
    };
  },
});
